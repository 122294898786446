.wrapper {
  color: white;
  width: 100%;
  height: 500px;
  background: url('../../../../styles/img/buildings-bg.jpg') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

  .overlay {
    position: absolute;
    background-color: #1b1b24;
    width: 100%;
    height: 500px;
    opacity: 0.5; 
  }

  .icon {
    z-index: 1;
    width: 85px;
    height: 85px;
    background: url('../../../../styles/img/stars.svg') no-repeat center / contain;
    margin-bottom: 30px;
  }

  .text {
    z-index: 1;
    font-size: 27px;
    font-weight: 300;
  }
}

@media (max-width: 576px) {
  .wrapper {
    .text {
      font-size: 25px;
    }
  }
}