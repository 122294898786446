.wrapper {
  width: 100%;
  background-color: #2185f5;
  padding: 85px 85px 95px 85px;
  color: white;
  display: flex;

  .title {
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 21px;   
    margin-bottom: 30px;
  }

  .column {
    width: 40%;
    margin-right: 60px;
    
    .text {
      font-size: 16px;
      line-height: 250%;
    }
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 50px 20px 30px 20px;
    flex-direction: column;

    .title {
      margin-bottom: 15px;
    }

    .column {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  
}