.wrapper {
  width: 100%;
  height: 100vh;
  background: url('../../../../styles/img/workspace-bg.jpg') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  
  .logo {
    width: 290px;
    height: 90px;
    background: url('../../../../styles/img/logo-white.png') no-repeat center / contain;
  }

  .title {
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
  }

  .description {
    max-width: 1000px;
    text-align: center;
    line-height: 170%;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    .logo {
      width: 145px;
      height: 45px;
    }

    .title {
      font-size: 25px;
    }

    .description {
      font-size: 15px;
      width: 80%;
    }
  }
}