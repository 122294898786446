.wrapper {
  color: white;
  width: 100%;
  height: 525px;
  background: url('../../../../styles/img/city-bg.jpg') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .overlay {
    position: absolute;
    background-color: #1b1b24;
    width: 100%;
    height: 525px;
    opacity: 0.5; 
  }

  .icon {
    z-index: 1;
    width: 100px;
    height: 100px;
    background: url('../../../../styles/img/location.svg') no-repeat center / contain;
    margin-bottom: 10px;
  }

  .title {
    z-index: 1;
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .wrapper {

    .icon {
      width: 50px;
      height: 50px;
    }

    .title {
      font-size: 25px;
    }
  }
}