.wrapper {
  width: 100%;
  height: 450px;
  background: url('../../../../styles/img/businessman-bg.jpg') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  .text {
    text-align: center;
    line-height: 170%;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    .text {
      width: 80%;
      font-size: 15px;
    }
  }
}