.wrapper {
  color: white;
  width: 100%;
  height: 525px;
  background: url('../../../../styles/img/diary-bg.jpg') no-repeat center / cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 40px;
    margin-bottom: 45px;
  }

  .logo {
    width: 230px;
    height: 85px;
    background: url('../../../../styles/img/logo-white.png') no-repeat center / contain;
  }
}

@media (max-width: 576px) {
  .wrapper {

    .title {
      font-size: 25px;
      margin-bottom: 25px;
    }

    .logo {
      width: 145px;
      height: 45px;
    }
  }
}