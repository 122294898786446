.wrapper {
  color: white;
  background-color: #2185f5;
  width: 100%;
  height: 440px;
  padding: 40px 70px 70px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: 85px;
    height: 85px;
    background: url('../../../../styles/img/thumbs-up.svg') no-repeat center / cover;
    margin-bottom: 30px;
  }

  .title {
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  .text {
    text-align: center;
    line-height: 170%;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 35px 0;

    .icon {
      width: 45px;
      height: 45px;
      margin-bottom: 15px;
    }

    .title {
      font-size: 25px;
    }

    .text {
      font-size: 15px;
      width: 80%;
    }
  }
}