.wrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 15px;
  position: absolute;  
  z-index: 1;

  .navbar {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;

    .logo {
      background: url('../../styles/img/logo-white.png') no-repeat center / contain;
      width: 200px;
      height: 54px;
      margin: 13px 0;
    }

    .menu_list {
      display: flex;
      align-items: center;

      &__item {
        padding: 0 15px;
        transition: color 0.2s ease;

        &:hover {
          cursor: pointer;
          color: wheat;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .wrapper {
    font-size: 12px;

    .navbar {
      width: 90%;

      .logo {
        width: 100px;
        height: 27px;
      }

      .menu_list__item {
        padding: 0 5px;
      }
    }
  }
}