.wrapper {
  width: 80%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;

  .map {
    width: 80%;
    height: 250px;
    background: url('../../styles/img/map.png') no-repeat center / cover;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: 0;
    width: 100%;
    .map {
      width: 100%;
      height: 100%;
    }
  }
}
